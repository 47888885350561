<template>
  <component :is="integrationComponent" />
</template>

<script>
export default {
  data() {
    return {
      integrationComponent: null,
    };
  },
  created() {
    const profile = JSON.parse(localStorage.getItem("profile"));
    const companyOwner = profile.company_owner.toLowerCase() || 'all';
    import(`@integrations/${companyOwner}/views/IndexView.vue`)
      .then((comp) => {
        this.integrationComponent = comp.default;
      })
      .catch((error) => {
        console.log(error)
        import(`@integrations/all/views/IndexView.vue`)
          .then((comp) => {
            this.integrationComponent = comp.default;
          })
      });
  },
};
</script>
